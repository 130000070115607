import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Divider, Grid, InputBase, ListItemText, Menu, MenuItem, Paper, SvgIcon } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { Link, useLocation } from 'react-router-dom';

import { signOut } from "firebase/auth";
import { auth } from '../../api/firebase.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Login from '../influencer/Login';
import Register from '../influencer/Register';
import Home from '../influencer/Home.jsx';
import BrandHome from '../brand/BrandHome.jsx';

import { useParams } from "react-router-dom";

function Navigation(props) {

    const { username } = useParams();
    // console.log('inside navigation')
    console.log('username')
    console.log(username)


    const source = props.source;

    const navigate = useNavigate();

    const location = useLocation();

    const [user, setUser] = useState("");
    const [isUserFetched, setIsUserFetched] = useState(false);
    const [showPage, setShowPage] = useState(false);

    // const [firstName, setFirstName] = useState("")

    async function fetchInfluencerDetailsFromPhone(phone) {
        let data = {
            phone: phone,
        };

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': "application/json"
            })
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_details_from_phone', options);
            const body = await response.json();
            return body;
        } catch (error) {
            console.error("Error fetching influencer details:", error);
            throw error;  // Rethrow to handle it in the caller
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setIsUserFetched(true);
            if (user) {
                console.log('found user')
                setUser(user);
                const body = await fetchInfluencerDetailsFromPhone(user.phoneNumber);
                console.log('body')
                console.log(body)
                if (body.success) {
                    console.log('success 1')
                    console.log(body)
                    navigate(body.navigate);
                } else {
                    console.log('navigating to login 1')
                    navigate('/login');
                }
            } else {
                if (location.pathname.startsWith('/edit/')) {
                    console.log('navigating to login 2')
                    navigate('/login'); // Redirect if trying to access edit without being logged in
                } else {
                    console.log('handle this case for brand')
                    // navigate('/login');
                }
            }
        });
        return () => unsubscribe(); // Cleanup subscription
    }, [navigate, location.pathname]);


    // useEffect(() => {
    //     console.log('use effect started')
    //     console.log(user)
    //     console.log(isUserFetched)
    //     if (user == null || user == "") {
    //         if (isUserFetched) {
    //             console.log('1')
    //             console.log("no user found")
    //             navigate("/login")
    //         }
    //         else {
    //             onAuthStateChanged(auth, (user) => {
    //                 // User is signed in, see docs for a list of available properties
    //                 // https://firebase.google.com/docs/reference/js/auth.user
    //                 setIsUserFetched(true)
    //                 setUser(user)
    //                 console.log('user set')
    //                 if (user) {
    //                     var phone = user.phoneNumber;
    //                     console.log('fetching details')
    //                     var body = fetchInfluencerDetailsFromPhone(phone)
    //                     if (body['success']) {
    //                         var url = body['navigate']
    //                         var username = body['username']
    //                         // navigate to /register or edit/username based on flow
    //                         console.log('2')
    //                         console.log('navigating to register')
    //                         navigate(url)
    //                         setShowPage(true)
    //                     } else {
    //                         console.log('navigating to login')
    //                         navigate('/login')
    //                     }
    //                 }
    //             });
    //         }
    //     } else {
    //         var phone = user.phoneNumber;
    //         var body = fetchInfluencerDetailsFromPhone(phone)
    //         if (body['success']) {
    //             var url = body['navigate']
    //             var username = body['username']
    //             // navigate to /register or edit/username based on flow
    //             console.log('3')
    //             setShowPage(true)
    //             navigate(url)
    //         }
    //         else {
    //             console.log('navigating to login')
    //             navigate('/login')
    //         }
    //     }
    // }, [user])


    // if (showPage == false) {
    //     return (
    //         <Box>

    //         </Box>
    //     );
    // }

    // else if (source != undefined) {

    if (!isUserFetched) {
        return <div>Loading...</div>; // Show loading state until user fetch status is determined
    }

    return (
        <Box >
            {source == "login" && <Login />}
            {source == "register" && <Register user={user} />}
            {source == "home" && <Home user={user} username={username} />}
            {source == "brandHome" && <BrandHome user={user} username={username} />}
            {/* {source == "discovery" && <Discovery userData={user} showDashboard={showDashboard} />}
                {source == "community" && <CommunityHome userData={user} />} */}
        </Box >
    );
    // }
}

export default Navigation;