import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { base_color_button } from '../common/global';

function Pricing(props) {

    var contentPricing = props.pricing.pricing;
    var username = props.username;


    const [pricing, setPricing] = useState(() => {
        const localData = {
            reel: localStorage.getItem('reelPrice'),
            post: localStorage.getItem('postPrice'),
            story: localStorage.getItem('storyPrice'),
            ugc: localStorage.getItem('ugcPrice')
        };
        return {
            "reel": { ...contentPricing.reel, price: localData.reel || contentPricing.reel.price },
            "post": { ...contentPricing.post, price: localData.post || contentPricing.post.price },
            "story": { ...contentPricing.story, price: localData.story || contentPricing.story.price },
            "ugc": { ...contentPricing.ugc, price: localData.ugc || contentPricing.ugc.price }
        };
    });


    const [editPricing, setEditPricing] = useState(false)

    console.log('pricing')
    console.log(pricing)

    function formatNumber(number) {
        if (number >= 100000) {
            return (number / 100000).toFixed(0) + "L";
        }
        else if (number >= 1000) {
            return (number / 1000).toFixed(0) + "K";
        } else {
            return number;
        }
    }

    // function handleEditPrice(value, key) {
    //     if (key == 'reel') {
    //         setReelPrice(value)
    //     }
    //     else if (key == 'post') {
    //         setPostPrice(value)
    //     }
    //     else if (key == 'story') {
    //         setStoryPrice(value)
    //     }
    // }

    function handleEditPrice(value, key) {
        setPricing(prevPricing => ({
            ...prevPricing,
            [key]: { ...prevPricing[key], price: value }
        }));
    }

    function savePricing() {
        console.log('save pricing called');
        setPricing(prevPricing => ({
            ...prevPricing,
            "reel": { ...prevPricing.reel, "price": pricing.reel.price },
            "post": { ...prevPricing.post, "price": pricing.post.price },
            "story": { ...prevPricing.story, "price": pricing.story.price },
            "ugc": { ...prevPricing.ugc, "price": pricing.ugc.price }
        }));

        // Update local storage
        localStorage.setItem('reelPrice', pricing.reel.price);
        localStorage.setItem('postPrice', pricing.post.price);
        localStorage.setItem('storyPrice', pricing.story.price);
        localStorage.setItem('ugcPrice', pricing.ugc.price);

        setEditPricing(false);

        saveDetails()
    }


    async function saveDetails() {

        console.log('save details called')
        // localStorage.setItem('userBio', bio);
        // setEditDetails(false)
        let data = {
            username: username,
            influencerData: {
                pricing: pricing
            },
        }

        console.log('data')
        console.log(data)

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/update_mediakit_influencer_details', options)
            .then(result => result.json())
            .then(body => {
                console.log("body"); console.log(body);
            });
    }

    var evenStyle = { display: "flex", justifyContent: "right", paddingRight: "10px" }
    var oddStyle = { display: "flex", justifyContent: "left", paddingLeft: "10px" }

    var boxEvenStyle = { display: "flex", flexDirection: "column", justifyContent: "space-evenly", color: "black", background: "#DCDCDC", borderRadius: "15px", width: "50%", padding: "20px 10px" }
    var boxOddStyle = { display: "flex", flexDirection: "column", justifyContent: "space-evenly", color: "black", background: "#DCDCDC", borderRadius: "15px", width: "50%", padding: "20px 10px" }

    const getItemStyle = (index, evenStyle, oddStyle) => ({
        ...index % 2 === 0 ? evenStyle : oddStyle,
        ...(index <= 1 ? { paddingBottom: "10px" } : { paddingTop: "10px" }),
    });

    const getBoxItemStyle = (index, boxEvenStyle, boxOddStyle) => ({
        ...index % 2 == 0 ? boxEvenStyle : boxOddStyle,
        ...(index <= 1 ? {} : {})
    })

    return (
        <Box sx={{ marginTop: "25px", padding: "0px 5px 0px 5px", color: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ border: "1px solid black", padding: "5px 15px 5px 15px", marginBottom: "10px", marginTop: "10px", borderRadius: "8px" }}>
                <Typography>Instagram</Typography>
            </Box>

            {/* {!editPricing && <Grid container>
                {Object.entries(pricing).map(([key, value], index) => (
                    <Grid item key={index} xs={6} sx={getItemStyle(index, evenStyle, oddStyle)}>
                        <Box sx={getBoxItemStyle()}>
                            <Typography variant='h5' sx={{ fontWeight: "900" }}>₹ {formatNumber(value.price)}</Typography>
                            <Typography variant='body1'>{value.quantity} {key}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>} */}

            {!editPricing && <Grid container sx={{ marginTop: "15px" }}>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", paddingRight: "10px", paddingBottom: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", color: "black", background: "#DCDCDC", borderRadius: "15px", width: "50%", height: "60%", padding: "25px 15px", marginRight: "10px", marginBottom: "10px" }}>
                        <Typography sx={{ fontWeight: "900", fontSize: "28px" }}>₹ {formatNumber(pricing.reel.price)}</Typography>
                        <Typography variant='subtitle2'>1 Reel</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "left", paddingLeft: "10px", paddingBottom: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", color: "black", background: "#DCDCDC", borderRadius: "15px", width: "50%", height: "60%", padding: "25px 15px", marginLeft: "10px", marginBottom: "10px" }}>
                        <Typography sx={{ fontWeight: "900", fontSize: "28px" }}>₹ {formatNumber(pricing.post.price)}</Typography>
                        <Typography variant='subtitle2'>1 Post</Typography>
                    </Box>
                </Grid>

                {/* <Grid item xs={12} sx={{ height: "20px" }} /> */}

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", paddingRight: "10px", paddingTop: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", color: "black", background: "#DCDCDC", borderRadius: "15px", width: "50%", height: "60%", padding: "25px 15px", marginRight: "10px", marginTop: "10px" }}>
                        <Typography sx={{ fontWeight: "900", fontSize: "28px" }}>₹ {formatNumber(pricing.story.price)}</Typography>
                        <Typography variant='subtitle2'>1 Story</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "left", paddingLeft: "10px", paddingTop: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", color: "black", background: "#DCDCDC", borderRadius: "15px", width: "50%", height: "60%", padding: "25px 15px", marginLeft: "10px", marginTop: "10px" }}>
                        <Typography sx={{ fontWeight: "900", fontSize: "28px" }}>₹ {formatNumber(pricing.ugc.price)}</Typography>
                        <Typography variant='subtitle2'>1 UGC</Typography>
                    </Box>
                </Grid>
            </Grid>}


            {editPricing && (
                <Grid container sx={{ gap: "5px", display: "flex", justifyContent: "space-evenly" }}>
                    {Object.entries(pricing).map(([key, value]) => (
                        <Grid item xs={5} key={key}>
                            <Box sx={{ background: "white", padding: "15px", marginBottom: "10px", height: "fit-content", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <TextField
                                    sx={{ fontWeight: "900", fontSize: "20px" }}
                                    label='Enter Price'
                                    value={value.price}
                                    onChange={(e) => handleEditPrice(e.target.value, key)}
                                />
                                <Typography sx={{ fontWeight: "900" }}>{value.quantity} {key}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}

            {!editPricing && <Button sx={{ background: base_color_button, color: "white", marginTop: "20px" }} onClick={() => setEditPricing(true)}>Edit</Button>}
            {editPricing &&
                <Box sx={{ gap: "20px", display: "flex", justifyContent: "center" }}>
                    <Button sx={{ background: base_color_button, color: "white" }} onClick={() => savePricing()}>Save</Button>
                    <Button sx={{ background: base_color_button, color: "white" }} onClick={() => setEditPricing(false)}>Cancel</Button>
                </Box>
            }
        </Box>
    )
}

export default Pricing