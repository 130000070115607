import React from 'react'
import { Box, Card, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import PieCharts from '../common/PieCharts'

function AudienceDemographics(props) {

    const audience = props.audience;

    var audienceAge = audience.audienceAge
    var audienceCity = audience.audienceCity
    var audienceGender = audience.audienceGender

    // CITY
    // var totalCityCount = 100;
    // var cityDist = Object.values(audienceCity);
    // var cityLabels = Object.keys(audienceCity);
    // var finalCityDist = cityDist.map(element => (element / totalCityCount) * 100);
    const sortedCities = Object.entries(audienceCity)
        .sort((a, b) => b[1] - a[1]) // Sort the array by value in descending order
        .slice(0, 3); // Get the top three items

    // GENDER
    var genderDistribution = Object.values(audienceGender);
    var genderLabels = Object.keys(audienceGender);

    // AGE
    // var ageDistribution = Object.values(audienceAge).slice(0, 4);
    // var ageLabels = Object.keys(audienceAge).slice(0, 4);

    const sortedAges = Object.entries(audienceAge)
        .sort((a, b) => b[1] - a[1]) // Sort the array by value in descending order
        .slice(0, 2); // Get the top two items

    // Calculate the sum of the top two values
    const topTwoSum = sortedAges.reduce((acc, item) => acc + item[1], 0);

    // Calculate the "Others" category value
    const others = 100 - topTwoSum;

    // Include "Others" in the results
    const finalSortedAges = [
        ...sortedAges,
        ["Others", others]
    ];

    var ageLabels = finalSortedAges.map(item => item[0]);
    var ageDistribution = finalSortedAges.map(item => item[1]);


    return (
        <Box sx={{ background: "white", color: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ border: "1px solid black", padding: "5px 15px 5px 15px", margin: "20px 0px 10px 0px", borderRadius: "8px" }}>
                <Typography>City Distribution</Typography>
            </Box>

            <Grid container style={{ marginBottom: "20px", padding: "15px" }}>
                {/* <Grid item xs style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}> */}
                {/* {cityLabels.map((value, index) => (
                        <Grid container key={index} style={{ padding: "5px 20px 5px 20px" }}>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <Typography >{value}</Typography>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <Box style={{ width: "90%", color: "#B169FE", position: "relative" }}>
                                        <LinearProgress variant="determinate" color='inherit' value={finalCityDist[index]} style={{ height: "2vh", borderRadius: "5px", border: "1px solid white" }} />
                                    </Box>
                                    <Typography>{finalCityDist[index].toFixed(0)}%</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    ))} */}
                {/* </Grid> */}

                {sortedCities.map((value, index) => (
                    <Grid item xs={4} key={index} sx={{ display: "flex", justifyContent: "center", }}>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", color: "black" }}>
                            <Typography variant='h4' sx={{ fontWeight: "900" }}>{value[1]}%</Typography>
                            <Typography>{value[0]}</Typography>
                        </Box>
                    </Grid>))}

                <Grid item xs sx={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid black", padding: "5px 15px 5px 15px", margin: "20px 0px 10px 0px", borderRadius: "8px" }}>
                        {/* <Typography variant="h6"></Typography> */}
                        <Typography>Gender Distribution</Typography>
                    </Box>
                    <Grid item style={{ width: "100%", height: "100%", }}>
                        <PieCharts labels={genderLabels} distribution={genderDistribution} type="gender" />
                    </Grid>
                </Grid>

                <Grid item xs sx={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid black", padding: "5px 15px 5px 15px", margin: "20px 0px 10px 0px", borderRadius: "8px" }}>
                        <Typography>Age Distribution</Typography>
                    </Box>
                    <Grid item style={{ width: "100%", height: "100%", }}>
                        <PieCharts labels={ageLabels} distribution={ageDistribution} type="age" />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AudienceDemographics