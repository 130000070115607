import React, { useState } from 'react'
import PieCharts from '../common/PieCharts'
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Avatar, Box, Button, Card, Divider, Grid, LinearProgress, Modal, Slide, TextField, Typography } from '@mui/material'
import { base_color_button } from '../common/global';
import LockedData from './LockedData';

const style = {
    position: "absolute",
    top: '60%',
    border: "1px solid black",
    // transform: 'translate(0%, 50%)',
    width: "28rem",
    height: "40%",
    bgcolor: 'background.paper',
    boxShadow: 9,
    // p: 1,
    overflow: "auto"
};

function Insights(props) {

    const username = props.username;
    const insights = props.insights;
    var metrics = insights.metrics;

    // const [highlightsBrands, setHighlightsBrands] = useState(['sprite_india', 'mcaffeineofficial', 'leaf.studios', 'emprallofficial']);
    const [highlightsBrands, setHighlightsBrands] = useState(insights['topBrandsWorkedWith']);


    function formatNumber(number) {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(0) + "B";
        }
        if (number >= 1000000) {
            return (number / 1000000).toFixed(0) + "M";
        }
        else if (number >= 1000) {
            return (number / 1000).toFixed(0) + "K";
        } else {
            return number;
        }
    }



    return (
        <Box sx={{ marginTop: "30px", display: "flex", flexDirection: "column", justifyContent: "space-between", marginBottom: "30px" }}>
            <Grid container sx={{ marginTop: "15px" }}>

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", paddingRight: "10px", paddingBottom: "10px" }}>
                    <Box sx={{ background: "#F1EEF9", gap: "10px", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginRight: "10px", marginBottom: "10px" }}>
                        <Box sx={{}}>
                            <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>{formatNumber(metrics["followers"])}</Typography>
                        </Box>
                        <Box >
                            <Typography variant='caption'>Followers</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "left", paddingLeft: "10px", paddingBottom: "10px" }}>
                    <Box sx={{ background: "#F1EEF9", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginLeft: "10px", marginBottom: "10px" }}>
                        <Box>
                            <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>{formatNumber(metrics["engagementRate"])}%</Typography>
                        </Box>
                        <Box>
                            <Typography variant='caption'>Engagement Rate</Typography>
                        </Box>
                    </Box>
                </Grid>

                {/* <Grid item xs={12} sx={{ height: "20px" }} /> */}

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", paddingRight: "10px", paddingTop: "10px" }}>
                    <Box sx={{ background: "#F1EEF9", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginRight: "10px", marginTop: "10px" }}>
                        <Box>
                            <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>{formatNumber(metrics["monthlyImpressions"])}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='caption'>Monthly Impressions</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "left", paddingLeft: "10px", paddingTop: "10px" }}>
                    <Box sx={{ background: "#F1EEF9", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginLeft: "10px", marginTop: "10px" }}>
                        <Box>
                            <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>{formatNumber(metrics["monthlyReach"])}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='caption'>Monthly Reach</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* {highlightsBrands.length > 0 && <Box sx={{ marginTop: "40px", color: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ border: "1px solid black", padding: "5px 15px 5px 15px", borderRadius: "8px" }}>
                    <Typography>Top Brands Worked With</Typography>
                </Box>
                <Grid container sx={{ display: "flex", marginTop: "10px", justifyContent: "center" }}>
                    {highlightsBrands.map((value, index) => (
                        <Grid item xs={6} key={index} style={{ marginBottom: "20px", width: "fit-content", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "5px" }}>
                            <Avatar sx={{ width: "60px", height: "60px", cursor: "pointer", border: "1px solid white" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} />
                            <Typography style={{ fontSize: "15px" }}>{value}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>} */}

            {/* <Box sx={{ marginTop: "40px", color: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Button sx={{ background: base_color_button, color: "white", padding: "10px", ":hover": "null" }}>
                    Collab with me
                </Button>
            </Box> */}
        </Box>
    )
}

export default Insights