import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FacebookLogin from "react-facebook-login";
import instagramLogo from "../../assets/instagramLogo.png"
import { useNavigate } from 'react-router-dom';


function InstagramLogin(props) {

    const navigate = useNavigate();

    const userData = props.userData;
    const phone = props.phone;
    console.log('phone number')
    console.log(phone)

    const [isLoading, setIsLoading] = useState(false)
    const [instaSettings, setInstaSettings] = useState(false)
    const [isInstagramActive, setIsInstagramActive] = useState(false)


    async function enrichMediaKitData(username) {

        console.log('starting enrich media')
        console.log(username)

        // setIsLoading(true)
        // const token2 = await userData.getIdToken()

        let data = {
            username: username
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/enrich_mediakit_instagram_data', options)
            .then(result => result.json())
            .then(body => {

                console.log('enrich body');
                console.log(body);

                if (body == "Success") {
                    setIsInstagramActive(true);
                    setIsLoading(false)
                    console.log('details saved successfully. now navigating')
                    navigate(`/edit/${username}`)
                }
                else {
                    console.log('error while enriching data')
                }
            }).catch((err) => {
                console.log('enrich media error');
                console.log(err);
                setIsLoading(false);
            });;
    }

    // useEffect(() => {
    //     checkActiveInstaUsernameConnection()
    // }, [])

    function handleLogout() {
        console.log("LOGOUT SUCCESSFULL")
    }

    function handleSubscribe(fbPageId, pageAccessToken) {
        // console.log("ENTERED SUBSCRIPTION");

        window.FB.api(
            "/" + fbPageId + "/subscribed_apps?access_token=" + pageAccessToken,
            "POST",
            { subscribed_fields: "mention,messages" },
            function (response) {
                // console.log("Subscribe called");
                console.log(response);
            }
        );
    }

    async function responseFacebook(response) {
        console.log("Response access token");
        console.log(response);
        console.log(response.accessToken);
        console.log(phone);

        // create instagram settings
        // const token2 = await userData.getIdToken();

        let data = { accessToken: response.accessToken, phone: phone }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        console.log('creating insta settings')

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_instagram_settings_mediakit', options)
            .then(result => result.json())
            .then(body => {
                console.log("SETTINGS CREATED: " + body);
                if (body["result"] == "Success") {
                    setIsLoading(true)
                    handleSubscribe(body["fbPageId"], body["pageAccessToken"])

                    // UNCOMMENT AFTER PERMISSION IS GRANTED

                    var res_username = body['username']
                    console.log('returned username');
                    console.log(res_username);

                    // var username = 'snitch.co.in'
                    // hit this
                    enrichMediaKitData(res_username)

                    // navigate(`/creatingInstaConnection/${phone}`)
                }
            }).catch((err) => {
                console.log('error');
                console.log(err);
                setIsLoading(false);
            });


        // await fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_instagram_settings_mediakit_temp', options)
        //     .then(result => result.json())
        //     .then(body => {
        //         console.log("SETTINGS CREATED: " + body);
        //         if (body["success"]) {
        //             setIsLoading(true)

        //             // UNCOMMENT AFTER PERMISSION IS GRANTED
        //             // handleSubscribe(body["fbPageId"], body["pageAccessToken"])


        //             // var res_username = body['username']
        //             // console.log('returned username');
        //             // console.log(res_username);

        //             // // var username = 'snitch.co.in'
        //             // // hit this
        //             // enrichMediaKitData(res_username)

        //             navigate(`/checkingInstaConnection/${phone}`)
        //         } else {
        //             console.log('error while saving instagram settings')
        //         }
        //     }).catch((err) => {
        //         console.log('error');
        //         console.log(err);
        //         setIsLoading(false);
        //     });
    }

    if (isLoading) {
        return (
            <Box><CircularProgress style={{ color: "black", }} size={50} /></Box>
        )
    } else {
        return (
            <>
                {
                    isInstagramActive &&
                    <Button sx={{ border: "1px solid black", color: "black", width: "auto", height: "45px", gap: "10px" }} onClick={() => handleLogout()}>
                        <img
                            src={instagramLogo}
                            alt="instagram-icon"
                            border="0"
                            width="30px"
                            height="30px"
                        />
                        Connected
                    </Button>
                }
                {
                    !isInstagramActive &&
                    <div className="content">
                        <FacebookLogin
                            size="large"
                            appId="338563195134203"
                            autoLoad={false}
                            disableMobileRedirect={true}
                            fields="name,email,picture"
                            scope="instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,pages_manage_metadata,instagram_manage_comments, pages_messaging,instagram_manage_messages,business_management"
                            callback={responseFacebook}
                        />
                    </div>
                }
            </>
        )
    }
}

export default InstagramLogin