import React, { useState, useEffect } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { base_color_button } from '../common/global';
import DoneIcon from '@mui/icons-material/Done';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Box, Button, Modal, SvgIcon, TextField, Typography } from '@mui/material';

const theme1 = createTheme({
    palette: {
        primary: {
            main: base_color_button,
        },
    },
});
const theme2 = createTheme({
    palette: {
        primary: {
            main: "#4ECA5C",
        },
    },
});

function LockedData(props) {
    var page = props.page;
    var handleSubmitDetails = props.onSubmitDetails;

    const [submitDetails, setSubmitDetails] = useState(false);
    const [email, setEmail] = useState("");
    const [brandName, setBrandName] = useState("");
    const [phone, setPhone] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setEmail(""); setBrandName(""); setPhone(""); }



    const style = {
        position: 'absolute',
        top: '50%',
        borderRadius: "15px",
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "30vh",
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const style1 = { display: "flex", flexDirection: "column", alignItems: 'center', gap: "20px", height: "50vh", justifyContent: "center", alignItems: "center" };
    const style2 = { display: "flex", flexDirection: "column", alignItems: 'center', gap: "20px", marginTop: "20px", justifyContent: "center", alignItems: "center" };

    useEffect(() => {
        validateForm();
    }, [email, brandName, phone, emailError, phoneError]);

    const validateEmail = (email) => {
        if (email.includes("gmail")) {
            setEmailError("Please enter a business email");
        } else {
            setEmailError("");

        }
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phone)) {
            setPhoneError("Please enter a valid 10-digit phone number");
        } else {
            setPhoneError("");
        }
    };

    const validateForm = () => {
        if (email && brandName && phone && !emailError && !phoneError) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setEmail(email);
        validateEmail(email);
    };

    const handlePhoneChange = (e) => {
        const phone = e.target.value;
        setPhone(phone);
        validatePhone(phone);
    };


    return (
        <Box>
            <Box sx={page === "insights" ? style2 : style1}>
                {/* {page !== "insights" && <LockIcon fontSize='large' />} */}
                {!submitDetails &&
                    <ThemeProvider theme={theme1}>
                        <Button startIcon={<HandshakeIcon fontSize='large' />} variant="contained" sx={{ color: "white", width: "100%", fontSize: "18px", height: "60px" }} onClick={handleOpen}>Collab With Me</Button>
                    </ThemeProvider>
                }
                {submitDetails &&
                    <ThemeProvider theme={theme2}>
                        <Button startIcon={<WhatsAppIcon fontSize='large' />} variant="contained" sx={{ color: "white", width: "100%", fontSize: "18px", height: "60px" }} onClick={() => { console.log("whatsapp clicked") }} >Whatsapp Me</Button>
                    </ThemeProvider>
                }
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {!submitDetails &&
                        <Box>
                            <Typography variant="h6" sx={{ marginBottom: "10px" }}>Enter your details</Typography>
                            <Box sx={{ marginBottom: "15px" }}>
                                <Typography>
                                    Enter your Brand Name
                                </Typography>
                                <TextField
                                    sx={{ width: "100%" }}
                                    placeholder='Enter Brand Name'
                                    size="small"
                                    value={brandName}
                                    onChange={(e) => setBrandName(e.target.value)}
                                />
                            </Box>

                            <Box sx={{ marginBottom: "15px" }}>
                                <Typography>
                                    Enter your Business Email
                                </Typography>
                                <TextField
                                    sx={{ width: "100%" }}
                                    placeholder='Enter Email'
                                    size="small"
                                    value={email}
                                    onChange={handleEmailChange}
                                    error={!!emailError}
                                    helperText={emailError}
                                />
                            </Box>

                            <Box sx={{ marginBottom: "15px" }}>
                                <Typography>
                                    Enter your Phone No.
                                </Typography>
                                <TextField
                                    sx={{ width: "100%" }}
                                    placeholder='Enter Phone No'
                                    size="small"
                                    type="number"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    error={phoneError}
                                    helperText={phoneError}
                                />
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    sx={{ width: "100%", background: (!isFormValid ? "#DCDCDC" : base_color_button), color: "white" }}
                                    disabled={!isFormValid}
                                    onClick={() => { setSubmitDetails(true); handleSubmitDetails(); }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>}

                    {submitDetails && <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <DoneIcon fontSize='large' />
                            <Typography>Details Submitted Successfully</Typography>
                            <Typography>Contact Me here</Typography>
                        </Box>
                        <Box sx={{ display: "flex", width: "100%" }}>
                            <Button startIcon={<WhatsAppIcon />} sx={{ width: "100%", background: "#4ECA5C", color: "white" }}>Whatsapp me</Button>
                        </Box>
                    </Box>}
                </Box>
            </Modal>
        </Box>
    )
}

export default LockedData;
